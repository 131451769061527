let origin = window.ORIGIN;
// 工单详情
const workOrderDetailsUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/workOrderDetails`;
// 工单列表
const workOrderListUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/workOrderList`;
// 受理人列表
const receiverListUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/receiverList`;
// 转派工单
const redeployWorkOrderUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/redeployWorkOrder`;
// 工单类型列表
const workOrderTypeListUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/workOrderTypeList`;
// 工单处理
const manageWorkOrderUrl = `${origin}/gateway/hc-serve/miniapi/snapshot/manageWorkOrder`;

export {
  workOrderDetailsUrl,
  workOrderListUrl,
  receiverListUrl,
  redeployWorkOrderUrl,
  workOrderTypeListUrl,
  manageWorkOrderUrl,
};
